import { Browser } from "@capacitor/browser";
import { Image, Text, Title, useModalState } from "@clipboard-health/ui-react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { Avatar, Button, CardActions, CardContent, Link, Rating, Stack } from "@mui/material";
import { type AgentStats } from "@src/appV2/Accounts/WorkplaceReferrals/api/useWorkerStats";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";

import { REFERRAL_SHIFT_ATTENDANCE_RATE } from "../../constants";
import { useWorkerToWorkplaceAffiliateExperiment } from "../../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../../types";
import { getWorkerToWorkplaceVariantName } from "../../utils/getWorkerToWorkplaceVariantName";
import { ShareYourProfileDialog } from "../ShareYourProfileDialog/ShareYourProfileDialog";
import lockImage from "./closedLock.svg";

const MIN_SHIFT_ATTENDANCE_RATE = 70;

interface PromoteYourProfileCardProps {
  agent: Worker;
  avatarUrl?: string;
  agentStats: AgentStats;
  referralCode: string;
}
export function PromoteYourProfileCard(props: PromoteYourProfileCardProps) {
  const { agent, avatarUrl, agentStats, referralCode } = props;
  const shareYourProfileModalState = useModalState();
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();

  const isEligibleForPromote = agentStats.shiftAttendanceRate >= MIN_SHIFT_ATTENDANCE_RATE;

  return (
    <>
      <CardContent>
        <Stack spacing={2}>
          <Title bold component="h1" variant="h4">
            Promote Your Profile
          </Title>
          <Text>
            Share your profile & stats with workplace staff. Invite them to book shifts with you on
            Clipboard!
          </Text>
          <Stack
            spacing={1}
            alignItems="center"
            sx={{
              background: (theme) => theme.palette.background.paper,
              padding: 2,
            }}
          >
            {isEligibleForPromote ? (
              <>
                <Stack alignItems="center" spacing={1}>
                  <Avatar alt={agent.name} src={avatarUrl} sx={{ width: 128, height: 128 }} />
                  <Text variant="h3">{agent.name}</Text>
                  <Text variant="h3">{agentStats.qualifications.join(", ")}</Text>
                  <Stack direction="row" spacing={1}>
                    <Rating
                      readOnly
                      value={agentStats.averageRating}
                      sx={{ color: (theme) => theme.palette.primary.dark }}
                    />
                    <Text>
                      {agentStats.ratingsCount === 1
                        ? "1 review"
                        : `${
                            agentStats.ratingsCount
                              ? `${agentStats.ratingsCount} reviews`
                              : "No reviews yet"
                          }`}
                    </Text>
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <CheckCircleOutlineIcon sx={{ color: (theme) => theme.palette.primary.dark }} />
                    <Text>{agentStats.completedShiftsCount} shifts completed</Text>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <CheckCircleOutlineIcon sx={{ color: (theme) => theme.palette.primary.dark }} />
                    <Text>{agentStats.shiftAttendanceRate}% shift attendance rate</Text>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <VerifiedUserOutlinedIcon
                      sx={{ color: (theme) => theme.palette.primary.dark }}
                    />
                    <Text>Background check passed</Text>
                  </Stack>
                </Stack>
              </>
            ) : (
              <Stack spacing={2} alignItems="center">
                <Image src={lockImage} alt="sharing profile lock" width="25%" />
                <Stack alignItems="center">
                  <Text sx={{ width: "60%", minWidth: 300 }} align="center">
                    This feature is only available for professionals with an attendance rating of
                    70% or higher. Your attendance rating is {agentStats.shiftAttendanceRate}%.{" "}
                    <Link
                      component="button"
                      onClick={async () => {
                        logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                          action: WorkplaceReferralAction.REFERRAL_SHIFT_ATTENDANCE_RATE_CLICKED,
                          component: WorkplaceReferralComponent.PROMOTE_PROFILE_SECTION,
                          variant: getWorkerToWorkplaceVariantName(
                            isWorkerToWorkplaceAffiliateProgramEnabled
                          ),
                          referralCode,
                        });
                        await Browser.open({ url: REFERRAL_SHIFT_ATTENDANCE_RATE });
                      }}
                    >
                      Learn more.
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant="contained"
          size="small"
          disabled={!isEligibleForPromote}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
              action: WorkplaceReferralAction.SHARE_PROFILE_CLICKED,
              component: WorkplaceReferralComponent.PROMOTE_PROFILE_SECTION,
              variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
              referralCode,
            });
            shareYourProfileModalState.openModal();
          }}
        >
          Share My Profile
        </Button>
      </CardActions>
      <ShareYourProfileDialog
        modalState={shareYourProfileModalState}
        agentId={agent.userId}
        referralCode={referralCode}
      />
    </>
  );
}
